import Loading from '@common/components/Loading';
import { getValueBindingComponent } from '@common/utils/component';
import Lottie from 'lottie-react-native';
import React, { useState } from 'react';
import { View } from 'react-native';
import createStyles from './style';
import { Text } from 'react-native-paper';

const Animation: React.FC<any> = (attributes) => {
  const [error, setError] = useState<string>();
  const styles = createStyles(attributes);
  const url = getValueBindingComponent(attributes, 'url');
  const loop = !!attributes.loop;

  return (
    <View style={styles.container}>
      {url ? (
        <Lottie
          source={{ uri: url }}
          webStyle={{
            width: attributes.width,
            height: attributes.height,
          }}
          style={{
            width: attributes.width,
            height: attributes.height,
          }}
          onAnimationFailure={(error) => {
            console.error('===Lottie Error===', error);
            setError(error);
          }}
          resizeMode="contain"
          loop={loop}
          autoPlay
        />
      ) : (
        <Loading size={'small'} />
      )}
      {error ? <Text style={{ color: '#FF0000' }}>{error}</Text> : <></>}
    </View>
  );
};

export default Animation;
